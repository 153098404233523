body {
  background-color: #f1f4f8;
  /* background-image: url('../public/bg.webp'); */
  background-repeat: no-repeat;
  background-size: cover;
  color: #5e6e82;
  height: 100dvh !important;
  font-family:"Oslo Sans",sans-serif;
}

[data-bs-theme="dark"] body {
  background-color: #5a5a5e; /* Mørkt tema */
}

[data-bs-theme="light"] #nav {
  background-color: #b7c2cc; /* Lyst tema */
}

[data-bs-theme="dark"] #nav {
  background-color: #39393a; /* Mørkt tema */
}

img {
  user-select: none;
}

#nav img {
  margin-top: 15px;
  filter: grayscale(1);
}

#nav img:hover {
  filter: grayscale(0.6);
  filter: invert(var(--value, 50%));
}

#nav .active, #nav .active:hover  {
  filter: invert(var(--value, 100%));
}

.nav-tab {
  position: relative;
  user-select: none;
}

.nav-tab-text {
  pointer-events: none;
  position: absolute;
  left: 50px;
  top: 65%; /* Juster for vertikal midtstilling */
  transform: translateY(-50%); /* Juster for vertikal midtstilling */
  opacity: 0;
  white-space: nowrap;
  background-color: #222429;
  padding: 5px;
  transition: opacity 0.3s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 15px;
  height: 35px;
  width: 120px;
  display: flex; /* Bruk Flexbox */
  align-items: center; /* Sentrer innholdet vertikalt */
  justify-content: center; /* Horisontal midtstilling */
  z-index: 9999;
}

.nav-tab:hover .nav-tab-text {
  opacity: 1;
}
.nav-tab-text:hover {
  opacity: 0 !important; /* Skjul .nav-tab-text når den er hovret */
}

.nav-tab-text {
  color: white !important;
}
[data-bs-theme="light"] .nav-tab-text span {
  color: white !important;
}

#headerTop {
  background-color: #f1f4f8;
}
[data-bs-theme="dark"] #headerTop {
  background-color: #5a5a5e; /* Mørkt tema */
}

#header {
  background-color: rgba(255,255,255,0.90);
  user-select: none;
}
[data-bs-theme="dark"] #header {
  background-color: rgba(22, 18, 18, 0.5);
}

span {
  color: #777;
}
[data-bs-theme="dark"] span {
  color: #fafafa;
}

@keyframes notificationAnimation {
  0% {
    transform: translateX(0deg);
    animation-timing-function: ease-in;
  }
  20% {
    transform: rotate(25deg);
    animation-timing-function: ease-out;
  }
  35% {
    transform: rotate(-25deg);
    animation-timing-function: ease-out;
  }
  45% {
    transform: translateX(0deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: rotate(25deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(-25deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotate(0deg);
  }
}

.img-notifications {
  animation: notificationAnimation 1.5s infinite;
  background-color: rgb(248, 127, 242);
  border-radius: 5px;
}




h1 {
  letter-spacing: 3px;
  font-size: 36px;
  color: #07375f;
}


h2 {
  width: '100%';
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

h3, h4  {
  padding: 8px;
  border-radius: 15px 15px 0 0;
  width: 100%;
  color: #5e6e82;
  margin: 0;
}

h5 {
  color: #0f0f0f;
  letter-spacing: 2px;
}

h6 {
  color: #0f0f0f;
}
[data-bs-theme="dark"] #mainContent h1,
[data-bs-theme="dark"] #mainContent h2,
[data-bs-theme="dark"] #mainContent h3,
[data-bs-theme="dark"] #mainContent h4,
[data-bs-theme="dark"] #mainContent h5, 
[data-bs-theme="dark"] #mainContent h6 {
  color: #ebe3e3ef !important;
}

.bg-heading-1 {
  background-color: #dce1e7;
}
[data-bs-theme="dark"] .bg-heading-1 {
  background-color: #1a3850;
}

.bg-1 {
  background-color: #f6f6f7;
  color: black;
}
[data-bs-theme="dark"] .bg-1 {
  background-color: #04284d60;
  color: white;
}

.bg-2 {
  background-color: #f6f6f7;
  color: black;
}
[data-bs-theme="dark"] .bg-2 {
  background-color: #020a1160;
  color: white;
}

.bg-3 {
  background-color: #fff;
}
[data-bs-theme="dark"] .bg-3{
  background-color: rgb(33,37,41);
}

.content {
  background-color: #ffffffef;
  color: #5e6e82;
  padding: 8px;
  border-radius: 0 0 10px 10px;
}
[data-bs-theme="dark"] .content {
  background-color: #5a5a5e;
}


.hover {
  transition: background-color 0.3s ease-in-out;
}
.hover:hover {
  background-color: rgba(248, 248, 248, 0.664) !important;
}

.formStar:hover {
  color: yellow;
}

/* Table */
table {
  width: 100%;

  th {
    background: #f1f4f8 !important;
    font-weight: bold;
    color: black !important;
  }

  th,
  td {
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 15px 10px;
    page-break-inside: avoid;
  }
}
[data-bs-theme="dark"] table th {
  background-color: #5a5a5e !important;
  color: #ebe3e3ef !important;
}
[data-bs-theme="dark"] table td {
  background-color: #434b4e !important;
  color: #ebe3e3ef !important;
}

.sticky-thc {
  th {
    position: sticky;
    top: 80px;
    height: 50px;
  }
}

/* Overview */
@media (max-width: 1100px) {
  #overview .box-1 {
    flex-basis: 100%;  /* Ta 30% bredde som standard */
  }
  #overview .box-2 {
    flex-basis: 100%;  /* Ta 30% bredde som standard */
  }
}
@media (min-width: 1100px) {
  #overview .box-1 {
    flex-basis: 50%;  /* Ta 30% bredde som standard */
    max-width: 800px;
  }
  #overview .box-2 {
    flex-basis: 20%;  /* Ta 30% bredde som standard */
    max-width: 600px;
  }
}

/* Cards */
[data-bs-theme="dark"] .card-header {
  background-color: #5a5a5e !important;
}
[data-bs-theme="dark"] .card-body {
  background-color: #434b4e !important;
  color: white !important;
}
/* Projects */
#projectTable {
  position: relative;
  margin-bottom: 60px;
}

#projectTableFooter {
  user-select: none;
  width: calc(100% - 100px);
  height: 60px;
  position: fixed;
  bottom: 0;
  background-color: #f1f4f8e5;
}
[data-bs-theme="dark"] #projectTableFooter {
  background-color: #5a5a5e;
}

#projectTable th {
  background-color: #fafafa;
  color: #344050;
  border: 0;
  font-size: 20px;
  letter-spacing: 1.2px;
  padding: 14px;
  text-align: center;
}

#projectTable tr:hover {
  font-weight: 500;
}

#projectTable th:first-child {
  border-radius: 0 0 0 0;
}
#projectTable th:last-child {
  border-radius: 0 0 0 0;
}

#projectTable .projType {
  font-size: 10px;
  letter-spacing: 2px;
}

#projectTable td {
  font-size: 18px;
  height: 75px;
  padding-top: 20px;
}

#projectTable td:not(:first-of-type) {
  text-align: center;
}

.mainProject tr{
  position: fixed !important;
  top: 0;
}
.mainProject td{
  background-color: #ffffff !important;
  color: #000;
}
.subProject td{
  background-color: #fafafa;
  color: #000;
  border-bottom: 1px #000 dotted;
}
.subsubProject td{
  background-color: #eef0f1;
  border-bottom: 1px dotted #000;
  color: #000;
}
[data-bs-theme="dark"] .mainProject td {
  background-color: #2b3436 !important;
}
[data-bs-theme="dark"] .subProject td {
  background-color: #30383e !important;
}
[data-bs-theme="dark"] .subsubProject td {
  background-color: #30383b !important;
}



.enhet-ANL td {
  background-color: #ddacce !important;
}
.enhet-Drift td {
  background-color: #b3c6cf !important;
}
[data-bs-theme="dark"] .enhet-ANL td {
  background-color: #411b35 !important;
}
[data-bs-theme="dark"] .enhet-Drift td {
  background-color: #0f3647 !important;
}


.pinned {
  position: sticky;
  z-index: 2;
}


.pinned:nth-child(even) td {
  background-color: #adbbcc !important;
}

.pinned:nth-child(odd) td {
  background-color: #dae0e7 !important;
}
[data-bs-theme="dark"] .pinned:nth-child(even) td  {
  background-color: #0f3647 !important;
}
[data-bs-theme="dark"] .pinned:nth-child(odd) td {
  background-color: #255366 !important;
}


.pinned:nth-child(1){
  top: 140px;
}

.subAmount {
  font-size: 12px;
}

/* History - in Projects */
.history_p_modal .modal-dialog {
  min-width: 60vw !important;
}
.historyRow {
  color: #000;
  padding: 10px 0 10px 0;
  margin: 0 0 25px 0;
  background-color: #f7f7f7;
}
[data-bs-theme="dark"] .historyRow {
  background-color: #282d31;
}

.historyTable tbody td {
  padding: 30px;
}
.historyTable thead th {
  padding-left: 30px;
}

.historyInfo {
  border-radius: 5px 5px 0 0;
  padding: 2px;
  color: #000000;
  font-size: large;
  font-weight: 800;
}

.historyComment {
  color: #080808;
  font-weight: 500;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  min-height: 40px;
}
.historyCommentUser{
  margin-bottom: 5px;
  color: #000;
}

/* History.js */
.historyJSTable thead th {
  background-color: #9c4030;
  color: #f7f7f7;
}
.historyJSTable tbody td {
  background-color: #f3f7fc;
  padding: 15px;
}
.history-sub:nth-child(odd) td {
  background-color: #f6f8f8 !important;
  color: #000;
  padding-bottom: 25px;
}
.history-sub:nth-child(even) td{
  background-color: #ebf1f3 !important;
  color: #000;
  padding-bottom: 25px;
}
[data-bs-theme="dark"] .history-sub:nth-child(odd) td {
  background-color: rgba(0,0,0,0.4) !important;
}[data-bs-theme="dark"] .history-sub:nth-child(even) td {
  background-color: rgba(0,0,0,0.2) !important;
}

.historyPinned {
  position: sticky;
  background-color: white;
  z-index: 2;
  top: 120px;
}
.historyPinned td {
  background-color: #e2e6e767;
  font-weight: 600;
}

.historyTema {
  max-width: 1400px;
}

.historyTema .temaType th {
  background-color: #96b3cf !important;
  padding-top: 25px;
  color: #f7f7f7;
}

.historyTema .temaTotal th {
  background-color: #bbc5cc !important;
  color: #fff;
}

[data-bs-theme="dark"] .historyTema .temaType th {
  background-color: #041e36 !important;
}
[data-bs-theme="dark"] .historyTema .temaTotal th {
  background-color: #363b3f !important;
}

/* Admin */
.admErrors .icon i {
  font-size: 28px;
  color: #d4b60c;
}

.admErrors h6 {
  margin-left: 5px;
  border-bottom: 1px dotted #000;
}
.admErrors span {
  margin-left: 15px;
  font-size: 14px;
}

.adm-submit {
  text-align: right;
  background-color: #f2f4fa;
  padding: 5px 10px 5px 0;
}
[data-bs-theme="dark"] .adm-submit {
  background-color: #222429;
}

.bg-striped {
  border: 1px #255366 dotted;
  padding: 20px;
}
[data-bs-theme="dark"] .bg-striped {
  border: 1px #fff dotted;
}

/* Tooltip */
.tooltip-inner {
  background-color: #131416;
  font-size: medium;
}

/******* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333030;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.back-to-top {
  transition: opacity 0.4s ease, transform 0.4s ease;
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none; /* Hindrer klikk når elementet er skjult */
}
.back-to-top.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Tillater klikk når elementet er synlig */
}

.hidden {
  display: none;
}
.hidden.show {
  display: block;
}


/**
* Font - Family
**/

@font-face{
  font-family:"Oslo Sans";
  font-weight:400;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Regular.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Regular.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:400;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-RegularItalic.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-RegularItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:300;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Light.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Light.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:300;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-LightItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:500;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Medium.woff2) format("woff2"),url(
      https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Medium.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:500;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-MediumItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:700;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Bold.woff2) format("woff2"),url(
      https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Bold.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:700;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-BoldItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Icons";
  font-style:normal;
  font-weight:normal;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloIcons.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloIcons.woff) format("woff")
}